#mystic-beauty img
{
    width:300px;
    max-width: 100%;
}

.place-card
{
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 10px;
    margin:10px
}