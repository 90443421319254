#reviews .col
{
    padding-bottom: 30px;
}
.cs-card
{
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;  width:400px;
    max-width: 100%;
}

.cs-card img
{
    width:70px;
    max-width: 100%;
    border-radius: 100%;
}