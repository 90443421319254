.card-blog2
{
    padding: 15px;
    height: 400px;
    overflow-y: scroll;
    width: 100%;
    border: 2px solid #aaa;
    margin-top: 5px;
}
.card-blog2 img
{
    width: 80%;
    height: 100px;
    object-fit: contain;
}