.lets
{
    background-color: #0097C4;
    color: #fff;
    border: none;
    border-radius: 21px;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
}
.lg,.lg1,.lg2
{
    border:8px solid #eee;
    margin-top: 10px;
    border-radius: 10%;
    padding: 15px;
    height: 100px;
    background-color: #fff9;
    transition: .2s;
}
.lg img
{
    margin-top: 250px;
}
.lg:hover
{
    transform: scale(1.1);
}
.lg1:hover
{
    transform: scale(1.1);
}
.lg2:hover
{
    transform: scale(1.1);
}