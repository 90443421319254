#packages h3
{
    font-weight: bold;
   text-shadow: 1px 1px #0008;
}
#packages p
{
    text-shadow: 2px 2px #0005;
}
.row1
{
    background: url("../../assets/NATURE_BG.png")no-repeat;
    background-size: cover;
    padding: 20px;
    height: 75vh;
}
.row2
{
    background: url("../../assets/nature/medical.webp")no-repeat;
    background-size: cover;
    padding: 20px;
    height: 75vh;
}
.row-3
{
    background: url("../../assets/nature/adventure.webp")no-repeat;
    background-size: cover;
    padding: 20px;
    height: 75vh;
}
.p-details
{
   margin-top: 20vh;
   
   border-radius: 15px;
   width:600px;
   padding: 50px;
   max-width: 100%;
}
.p-details2
{
   margin-top: 5vh;
}
.bg-dark2
{
    background-color: #0007;
}
.bg-white2
{
    background-color: #fff5;
}
.color-pink
{
    color: rgb(219, 146, 161);
}
.card-package
{
    border: 10px solid #fff;
    height: 425px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.card-package2
{
    border: 10px solid #fff;
    height: 250px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.card-package2 img
{
    border: 10px solid #fff;
    height: 200px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.card-package img
{
    width: 100%;
    height: 350px;
    box-shadow: rgba(0, 0, 0, 0.765) 0px 5px 15px;

}
.card-package span
{
    font-weight: bold;
    
    
}

.card-package2 span
{
    font-weight: bold;
    
    
}
.arrowdown
{
    width: 6px;
    height: 6px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: relative;
    transform: rotate(45deg);
    margin: 0 6px;
    transition: all .3s ease;
}
.arrowup
{
    width: 6px;
    height: 6px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: relative;
    transform: rotate(225deg);
    margin: 0 5px;
    transition: all .3s ease;
}
.arrow-button {
    display: flex;
    color: #fff;
    background-color: #00E692;
    padding: 10px 16px;
    border-radius: 20px;
    transition: all .3s ease;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    width: 130px;
    text-decoration: none;
}

.btn2
{

    color: #fff;
    background-color: #0375ad;
    display: block;
    border-radius: 20px;
    transition: all .3s ease;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    width: 100px;
   padding: 10px;
    text-align: center;
    text-decoration: none;
    
}

.arrow-button > .arrow {
    width: 6px;
    height: 6px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: relative;
    transform: rotate(-45deg);
    margin: 0 6px;
    transition: all .3s ease;
}

.arrow-button > .arrow::before {
    display: block;
    background-color: currentColor;
    width: 3px;
    transform-origin: bottom right;
    height: 2px;
    position: absolute;
    opacity: 0;
    bottom: calc(-2px / 2);
    transform: rotate(45deg);
    transition: all .3s ease;
    content: "";
    right: 0;
}

.arrow-button:hover > .arrow {
    transform: rotate(-45deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.arrow-button:hover > .arrow::before {
    opacity: 1;
    width: 5px;
}

.arrow-button:hover {
    background-color: #0CBC8B;
    color: #fff;
}