.book-now
{
    outline: none;
    border: none;
    transition: 0.4s linear;
    
}

.book-now:hover
{
    cursor: pointer;
    transform: scale(1.1);
}

#services{
    
}

#services p
{
    margin-left: 20px;
}
.vector
{
    width:300px;
    height: 300px;
    max-width: 100%;
    object-fit: contain;
}
.bg-transp2
{
    background-color: #dedede;
}


  
.caption
{
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 10px;
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
}
.caption h3{
    font-size: 25px;
}