body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "EXO-Regular";
  src: local("EXO"),
    url(./assets/fonts/exo.regular.otf) format("truetype");
}

@font-face {
  font-family: "EXO-Light";
  src: local("EXO"),
    url(./assets/fonts/exo.light.otf) format("truetype");
}


@font-face {
  font-family: "EXO-ExtraLight";
  src: local("EXO"),
    url(./assets/fonts/exo.extra-light.otf) format("truetype");
}


@font-face {
  font-family: "EXO-Medium";
  src: local("EXO"),
    url(./assets/fonts/exo.medium.otf) format("truetype");
}

@font-face {
  font-family: "EXO-DemiBold";
  src: local("EXO"),
    url(./assets/fonts/exo.demi-bold.otf) format("truetype");
}

@font-face {
  font-family: "EXO-Bold";
  src: local("EXO"),
    url(./assets/fonts/exo.bold.otf) format("truetype");
}

.demibold
{
  font-family: 'EXO-DemiBold';
}

.regular
{
  font-family: 'EXO-Regular';
}

.medium
{
  font-family: 'EXO-Medium';
}

.grey-color
{
  color:  #151237CF;
}

.grey-color2
{
  color: #413E5D;
}
.green-color
{
  color:#3DB677;
}
.white-color
{
  color: #fff;
}
.bg-grey
{
  background-color:#E7D9D92E;
  ;
}
.bg-blue
{
  background: #4463D0;

}
.bg-gray
{
  background-color: #D4D8E3;
}
.bg-transp
{
  background-color: rgba(0, 13, 57, 0.176);
  margin: 0;
  padding: 5px;
}
.white-line
{
  background-color: #fff;
  height: 2px;
  width: 100px;
  margin-top: 10px;
  margin-bottom: 15px;
}
h1,h3
{
  font-family: 'EXO-Bold';
  color:  #151237CF;
}
h5{
  font-family: 'EXO-Bold';
  color:  #151237CF;
}
p
{
  color: #413E5D;
}
.border-grey
{
  padding: 25px;
  box-shadow: 3px 4px 10px rgba(203, 205, 202, 0.5);
  border-radius: 19px;
  margin-top: 60px;
  text-align: center;
}
.justify
{
  text-align: justify;
  text-justify: inter-word;
}
.o-0:hover
{
  border: none;
}
.o-0:hover
{
  border: none;
}