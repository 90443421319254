.lets
{
    background-color: #0097C4;
    color: #fff;
    border: none;
    border-radius: 21px;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    
}
#home
{
   padding-bottom: 15vh;
    padding-top: 5vw;
}