.footer
{
    background: linear-gradient(#233E62,#010711);
}
.form-card
{
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}
.form-control
{
    border:2px solid #233E62;
}
.light-blue-color
{
    color: #C0E8FF;
}